'use client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';
import { useCookies } from 'next-client-cookies';
import { CookieStore, PartnerUpdateReferralLinkClientDTO } from '@exchanger/common';
import dynamic from 'next/dynamic'
import ExchangeWidget from '@/components/Widget/Exchange'
import { PartnerService } from '@/service/partner'
import { useMutation } from '@tanstack/react-query'

const WhyChooseUs = dynamic(() => import('./WhyChooseUs'))
const Stats = dynamic(() => import('./Stats'))
const Faq = dynamic(() => import('./Faq'))
const CustomerReviews = dynamic(() => import('./CustomerReviews'))
const AffiliateReferralProgram = dynamic(() => import('./AffiliateReferralProgram'))
const Partner = dynamic(() => import('./Partner'))

const Welcome = () => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const cookieStore = useCookies();

  const { mutate } = useMutation({
    mutationKey: ['partner-update-ref-link'],
    mutationFn: (data: PartnerUpdateReferralLinkClientDTO) => PartnerService.partnerUpdateRefLink(data),
  });


  useEffect(() => {
    const ref = searchParams.get('ref');
    if (ref) {
      console.log(ref)
      cookieStore.set(CookieStore.referralCode, ref, {
        expires: 60 * 60 * 24 * 365,
      });
      mutate({
        referralCode: ref,
        path: window.location.search
      })
    }
  }, [searchParams]);

  return (
    <>
      <div className='relative flex flex-col items-center gap-0 sm:gap-6 md:gap-9 md:p-0 sm:p-0'>
        <h1 className='text-white text-[28px] md:text-[42px] text-center p-4 font-semibold m-0 mt-6'>
          {t('title.h')} <b className='text-gradient-default font-extrabold'>{t('title.gradient')}</b> <b>{t('title.b')}</b>
        </h1>
        <h2 className='text-white text-sm md:text-lg leading-6 font-medium p-4 text-center m-0 whitespace-pre-line'>
          {t('subtitle')}
        </h2>
        <div className="flex flex-col items-center gap-0 sm:gap-6 md:gap-9 md:p-0 sm:p-0 mx-auto min-w-full">
          <ExchangeWidget />
        </div>
        
      </div>
      <Partner />
      <WhyChooseUs />
      <AffiliateReferralProgram />
      <Stats />
      <CustomerReviews />
      <Faq />
    </>
  );
};

export default Welcome;
