import React, { useState } from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  tooltipClassName?: string;
  minWidth?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, tooltipClassName, minWidth = '200px' }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="relative inline-block">
      <div
        className="tooltip-trigger"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={handleToggleTooltip}
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className={`tooltip-text bg-gray-800 text-white text-center rounded-lg p-1 absolute top-full left-1/2 transform -translate-x-1/2 mt-2 opacity-100 transition-opacity duration-300 ${tooltipClassName}`}
          style={{
            whiteSpace: 'normal',
            zIndex: 10,
            minWidth: minWidth,
            maxWidth: 'calc(50vw - 170px)',
            fontSize: '0.75rem',
            wordWrap: 'break-word',
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
