import Image from 'next/image';
import ChevronSvg from '@assets/svg/chevron.svg';
import { FC } from 'react';
import ChainBadge from '../ChainBadge/index';

interface CurrencyHeaderProps {
  coin: string;
  chain: string;
  name: string;
  imageSrc: string;
  showModal: boolean;
  handleImageError: () => void;
}

const CurrencyHeader: FC<CurrencyHeaderProps> = ({ coin, chain, name, imageSrc, showModal, handleImageError }) => {
  const isChainLong = chain.length > 9;

  return (
    <div className="flex items-center">
      <div className="hidden sm:flex flex-col justify-center items-center mr-1 h-full">
        <div className="rounded-full overflow-hidden flex justify-center items-center h-140 w-140">
          <Image
            src={imageSrc}
            alt={`Exchange ${coin}`}
            width={47}
            height={47}
            onError={handleImageError}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="flex sm:hidden flex-col justify-center items-center mr-1 h-full">
            <div className="rounded-full overflow-hidden flex justify-center items-center h-6 w-6">
              <Image
                src={imageSrc}
                alt={`Exchange ${coin}`}
                width={24}
                height={24}
                onError={handleImageError}
              />
            </div>
          </div>
          <span className="text-white text-lg uppercase">{coin}</span>
          <ChevronSvg
            className={`stroke-current text-white ml-2 transition-transform duration-300 ${showModal ? 'transform rotate-180' : ''}`}
          />
        </div>
        <div className="flex items-center">
          {!isChainLong && (
            <span className="text-gray-400 text-sm flex items-center">
              {name}
            </span>
          )}
          <ChainBadge chain={chain} isChainLong={isChainLong} />
        </div>
      </div>
    </div>
  );
};

export default CurrencyHeader;
