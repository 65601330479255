import { useEffect, useState } from 'react';
import CurrencyHeader from '../CurrencyHeader/index';
import Modal from '../Modal/index';
import { CurrencyFromData, CurrencyToData, NestedPairToData, PairFromData } from '@exchanger/common'

interface ExchangeCurrencyProps {
  coin: string;
  chain: string;
  name: string;
  pairsPopular?: PairFromData;
  isFixed: boolean;
  isToPairs: boolean;
  pairs: PairFromData | NestedPairToData;
  callback: (data: CurrencyToData | CurrencyFromData) => void;
}

const ExchangeCurrency = ({ coin, chain, name, pairs = {}, pairsPopular = {}, isFixed, isToPairs, callback }: ExchangeCurrencyProps) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(`/coins/${coin.toLowerCase()}.png`);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setImageSrc(`/coins/${coin.toLowerCase()}.png`);
  }, [coin]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [showModal]);

  const handleImageError = () => {
    setImageSrc('/coins/default.png');
  };

  const selectCurrency = (data: CurrencyToData | CurrencyFromData) => {
    setShowModal(false);
    return callback(data);
  };

  return (
    <div className="relative w-full">
      <button
        className="flex items-center p-2 rounded-2xl hover:bg-white hover:bg-opacity-10"
        onClick={() => setShowModal(true)}
      >
        <CurrencyHeader
          coin={coin}
          chain={chain}
          name={name}
          imageSrc={imageSrc}
          showModal={showModal}
          handleImageError={handleImageError}
        />
      </button>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        pairsPopular={pairsPopular}
        pairs={pairs}
        isFixed={isFixed}
        isToPairs={isToPairs}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectCurrency={selectCurrency}
      />
    </div>
  );
};

export default ExchangeCurrency;
