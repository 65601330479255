import React from 'react';

interface BlockProps {
  className?: string;
  children: React.ReactNode;
}

const WidgetBlock = ({ className, children }: BlockProps) => {
  return (
    <div className={`flex flex-col w-full max-w-[628px] mx-auto p-4 sm:p-6 lg:p-8 text-white ${className}`}>
      {children}
    </div>
  );
};

export default WidgetBlock;
