import { PartnerGetProfileResponseDTO, PartnerOrderWithdrawalCreateRequestClientDTO, PartnerOrderWithdrawalCreateResponseDTO, PartnerOrderWithdrawalGetRequestClientDTO, PartnerOrderWithdrawalGetResponseDTO, PartnerWithdrawalChainSupportedDTO, PartnerGenerateTwoFactorQrCodeResponseDTO, PartnerActivateTwoFactorRequestClientDTO, AuthJwtAccessDTO, PartnerGetApiKeyResponseDTO, PartnerUpdateReferralLinkClientDTO } from '@exchanger/common'
import { serviceFetch } from '../fetch'

export const PartnerService = {

	async getProfile(){
		return await serviceFetch<null, PartnerGetProfileResponseDTO>({
   			path: '/partner/profile',
    		method: 'POST'
		});
	},

	async getWithdrawalCurrencySupported(){
		return await serviceFetch<null, PartnerWithdrawalChainSupportedDTO>({
			path: '/partner/withdrawal/currency',
			method: 'GET'
		});
	},

	async withdrawalOrderCreate(data: PartnerOrderWithdrawalCreateRequestClientDTO){
		return await serviceFetch<PartnerOrderWithdrawalCreateRequestClientDTO, PartnerOrderWithdrawalCreateResponseDTO>({
			path: '/partner/withdrawal/order/create',
			method: 'POST',
			data: data,
		});
	},

	async withdrawalOrderList(data: PartnerOrderWithdrawalGetRequestClientDTO){
		return await serviceFetch<PartnerOrderWithdrawalGetRequestClientDTO, PartnerOrderWithdrawalGetResponseDTO>({
			path: '/partner/withdrawal/order/list',
			method: 'POST',
			data: data,
		});
	},

	async settingsTwoFactorGenerate(){
		return await serviceFetch<null, PartnerGenerateTwoFactorQrCodeResponseDTO>({
			path: '/partner/settings/two-factor/generate',
			method: 'GET',
		});
	},

	async settingsTwoFactorActivate(data: PartnerActivateTwoFactorRequestClientDTO){
		return await serviceFetch<PartnerActivateTwoFactorRequestClientDTO, AuthJwtAccessDTO>({
			path: '/partner/settings/two-factor/activate',
			method: 'POST',
			data: data,
		});
	},

	async settingsGetApi(){
		return await serviceFetch<null, PartnerGetApiKeyResponseDTO>({
			path: '/partner/settings/api',
			method: 'GET',
		});
	},

	async partnerUpdateRefLink(data: PartnerUpdateReferralLinkClientDTO){
		return await serviceFetch<PartnerUpdateReferralLinkClientDTO, null>({
			path: '/public/partner/link',
			method: 'POST',
			data: data
		});
	},


}