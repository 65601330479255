import React from 'react';

type TButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
interface ButtonProps extends TButtonProps {
  height: number;
  theme?: 'white' | 'error' | 'deposit-failed' | 'aff-program' | 'default' | 'error-go-exchange';
  rounded?: boolean;
  className?: string;
  textSize?: string;
  children: React.ReactNode;
}

const Button = ({ className, onClick, children, theme, height, textSize, rounded = false, disabled = false }: ButtonProps) => {
  let themeButton;
  switch (theme) {
    case 'white':
      themeButton = 'bg-black shadow-gradient-white hover:bg-neutral-900';
      break;
    case 'error':
      themeButton = 'border border-red-500 text-red-500 bg-[#1B1B1B] hover:bg-neutral-900 ';
      break;
    case 'error-go-exchange':
      themeButton = 'cursor-not-allowed	 bg-[#1B1B1B] hover:bg-neutral-900 ';
      break; 
    case 'deposit-failed':
      themeButton = 'border border-yellow-500 text-yellow-500 bg-[#1B1B1B] hover:bg-neutral-900';
      break;
    case 'aff-program':
      themeButton = 'border border-[#ea68a5] text-[#ffb7d9] bg-[#000000] max-w-[200px] min-w-[200px] hover:button-gradient-revers';
      break;
    case 'default':
      themeButton = 'button-gradient-default shadow-gradient-default bg-[#1B1B1B] hover:bg-neutral-900';
      break;
    default:
      themeButton = 'button-gradient-default shadow-gradient-default bg-[#1B1B1B] hover:bg-neutral-900';
      break;
  }

  return (
    <button
      style={{ height: `${height}px` }}
      className={`${themeButton} ${className} hover:shadow-xl ${rounded ? 'rounded-full' : 'rounded'} p-[1px]`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`text-white ${textSize ? textSize : 'text-base'} font-medium box-border h-full w-full ${rounded ? 'rounded-full' : 'rounded'} flex justify-center items-center`}>
        {children}
      </span>
    </button>
  );
};

export default Button;
