import { CalcAmountPairRequestDTO, CalcAmountPairResponse, CurrencyFromData, CurrencyToData, ExchangerPairDirectionClientResponseDTO, NestedPairToData, PairFromData } from '@exchanger/common'
import { serviceFetch } from '../fetch'

export const ExchangeService = {

	async getAmount(data: CalcAmountPairRequestDTO){
		return await serviceFetch<CalcAmountPairRequestDTO, CalcAmountPairResponse>({
			path: '/public/pair/amount',    		
			method: 'POST',
			data: data,
		});
	},

	async getPairs(fromSearch: string, toSearch: string) {
		const getEntries = ({
			defaultPairs,
			popularPairs,
			currencyId
		}: {
			defaultPairs?: PairFromData, 
			popularPairs?: PairFromData, 
			currencyId: string
		}) => {
			const pairs = defaultPairs ?? popularPairs;
			if (!pairs) {
				throw new Error('Both defaultPairs and popularPairs are undefined');
			}
			const keys = new Map(Object.entries(pairs));
			const key = keys.has(currencyId) ? currencyId : [...keys.keys()][0];
			return keys.get(key) as CurrencyFromData;
		};
	
		const { from, to, defaultPair, popularPairs } = await serviceFetch<null, ExchangerPairDirectionClientResponseDTO>({
			path: '/public/pairs',
			method: 'GET'
		});
	
		const createCurrencyMap = (currencies: CurrencyFromData[]) => {
			const map = new Map<string, CurrencyFromData>();
			for (const currency of currencies) {
				const currencyMap = map.get(currency.currency)
				if(currencyMap) {
					if(currency.chain === currency.currency) {
						map.delete(currency.currency)
						map.set(currency.currency, currency);
					} else if (currency.chain === 'ERC20') {
						map.delete(currency.currency)
						map.set(currency.currency, currency);
					}
				} else {
					map.set(currency.currency, currency);
				}
				currency.alias.forEach(alias => {
					if(!map.has(alias)) {
						map.set(alias, currency)
					}
				});
			}
			return map;
		};
	
		let currencyFromData: CurrencyFromData;
		let currencyToData: CurrencyToData;
	
		if (fromSearch !== 'false' && toSearch !== 'false' && fromSearch !== toSearch) {
			const currencyFromDataMap = createCurrencyMap([...Object.values(popularPairs),...Object.values(from)]);
			currencyFromData = currencyFromDataMap.get(fromSearch) ?? getEntries({ popularPairs, currencyId: defaultPair.currencyFrom });
	
			const currencyToDataMap = createCurrencyMap(Object.values(to[currencyFromData.key])) as Map<string, CurrencyToData>;
			currencyToData = currencyToDataMap.get(toSearch) ?? getEntries({
				defaultPairs: to[currencyFromData.key] as NestedPairToData,
				currencyId: defaultPair.currencyTo
			}) as CurrencyToData;
	
		} else {
			currencyFromData = getEntries({ popularPairs, currencyId: defaultPair.currencyFrom });
			
			currencyToData = getEntries({
				defaultPairs: to[defaultPair.currencyFrom] as PairFromData,
				currencyId: defaultPair.currencyTo
			}) as CurrencyToData;
		}
	
		return {
			from,
			to,
			popularPairs,
			currencyFrom: currencyFromData,
			currencyFromKey: `${currencyFromData.currency}-${currencyFromData.chain}`,
			currencyTo: currencyToData,
			currencyToKey: `${currencyToData.currency}-${currencyToData.chain}`,
		};
	}
	
	
	
	
	
	
	
	

}