import {
  CurrencyFromData,
  CurrencyToData,
  NestedPairToData,
  PairFromData,
} from '@exchanger/common';
import Image from 'next/image';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  pairsPopular?: PairFromData;
  pairs: PairFromData | NestedPairToData;
  isFixed: boolean;
  isToPairs: boolean;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectCurrency: (data: CurrencyToData | CurrencyFromData) => void;
}

const Modal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  pairsPopular = {},
  pairs = {},
  isFixed,
  isToPairs,
  searchTerm,
  setSearchTerm,
  selectCurrency,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!showModal) {
      setSearchTerm('');
    }
  }, [showModal, setSearchTerm]);

  if (!showModal) return null;

  const allPairs = { ...pairs, ...pairsPopular };

  const allPairsValues = Object.values(allPairs);

  const searchTerms = searchTerm.toLowerCase().split(' ').filter(Boolean);

  const filteredPairs = allPairsValues
    .filter((pair) => {
      const fields = [
        pair.currency.toLowerCase(),
        pair.chain.toLowerCase(),
        pair.name.toLowerCase(),
      ];
      const matchesAllTerms = searchTerms.every((term) =>
        fields.some((field) => field.includes(term))
      );

      if (isToPairs) {
        const currencyTo = pair as CurrencyToData;
        const fixedOrFloatMatch = isFixed
          ? currencyTo?.fixed === true
          : currencyTo?.float === true;
        return matchesAllTerms && fixedOrFloatMatch;
      }
      return matchesAllTerms;
    })
    .sort((a, b) => {
      const scoreA = calculateMatchScore(a, searchTerms);
      const scoreB = calculateMatchScore(b, searchTerms);
      return scoreB - scoreA;
    });

  function calculateMatchScore(
    pair: CurrencyFromData | CurrencyToData,
    searchTerms: string[]
  ) {
    let score = 0;

    const currency = pair.currency.toLowerCase();
    const chain = pair.chain.toLowerCase();
    const name = pair.name.toLowerCase();

    searchTerms.forEach((term) => {
      if (currency === term) {
        score += 30;
      } else if (currency.includes(term)) {
        score += 10;
      }

      if (chain === term) {
        score += 20;
      } else if (chain.includes(term)) {
        score += 5;
      }

      if (name === term) {
        score += 15;
      } else if (name.includes(term)) {
        score += 2;
      }
    });

    return score;
  }

  const handleClose = () => {
    setShowModal(false);
    setSearchTerm('');
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-[99999] bg-black bg-opacity-50"
      onClick={handleClose}
    >
      <div
        className="bg-[#1b1b1b] w-screen h-screen mx-auto p-4 pt-0 sm:pt-4 sm:p-4 sm:rounded-lg shadow-lg relative sm:w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl sm:h-auto sm:min-h-[60vh] sm:max-h-[90vh] sm:border sm:border-gray-700 sm:border-b-0 flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 sm:p-2 bg-[#1b1b1b] sm:bg-transparent z-[99999]">
          <div className="sm:hidden">
            <button
              className="w-[40px] h-[40px] flex items-center justify-center m-2 text-gray-400 bg-transparent rounded p-2 hover:bg-gray-700 hover:text-white"
              onClick={handleClose}
              style={{ fontSize: '24px' }}
            >
              ←
            </button>
          </div>
          <h2 className="text-xl sm:text-2xl font-semibold text-white">
            {t('exchange:widget.select.title')}
          </h2>
          <button
            className="w-[40px] h-[40px] flex items-center justify-center m-2 text-gray-400 bg-transparent rounded p-2 hover:bg-gray-700 hover:text-white"
            onClick={handleClose}
            style={{ fontSize: '28px' }}
          >
            &times;
          </button>
        </div>
        <input
          placeholder={t('exchange:widget.select.search')}
          className="w-full h-12 border border-gray-600 rounded-lg px-4 py-2 mb-4 mt-16 sm:mt-4 bg-[#2d2d2d] text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="overflow-y-auto overflow-hidden flex-grow">
          {searchTerm === '' && Object.keys(pairsPopular).length > 0 && (
            <>
              <div className="text-white font-semibold mb-2">
                {t('exchange:widget.select.popular')}
              </div>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                {Object.values(pairsPopular).map((pair, index) => (
                  <div
                    key={`popular-${index}`}
                    className="flex items-center cursor-pointer gap-2 hover:bg-gray-800 p-2 rounded"
                    onClick={() => selectCurrency(pair)}
                  >
                    <Image
                      src={`/coins/${pair.currency.toLowerCase()}.png`}
                      alt={`Exchange ${pair.currency}`}
                      width={32}
                      height={32}
                      className="w-8 h-8"
                    />
                    <div>
                      <span className="text-lg text-white break-words">
                        {pair.currency} ({pair.chain})
                      </span>
                      <div className="text-sm text-gray-400">{pair.name}</div>
                    </div>
                  </div>
                ))}
              </div>
              <hr className="border-t my-4 border-gray-600" />
            </>
          )}
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
            {filteredPairs.map((pair, index) => (
              <div
                key={`pair-${index}`}
                className="flex items-center cursor-pointer gap-2 hover:bg-gray-800 p-2 rounded"
                onClick={() => selectCurrency(pair)}
              >
                <Image
                  src={`/coins/${pair.currency.toLowerCase()}.png`}
                  alt={`Exchange ${pair.currency}`}
                  width={32}
                  height={32}
                  className="w-8 h-8"
                />
                <div>
                  <span className="text-lg text-white break-words">
                    {pair.currency} ({pair.chain})
                  </span>
                  <div className="text-sm text-gray-400">{pair.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
