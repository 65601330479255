import React, { FC } from 'react';


interface ChainBadgeProps {
  chain: string;
  isChainLong: boolean;
}

const ChainBadge: FC<ChainBadgeProps> = ({ chain, isChainLong }) => {
  const popularChains = new Map<string, string>([
    ['ETH', 'bg-blue-500'],
    ['ERC20', 'bg-blue-500'],
    ['BTC', 'bg-[#ee931b]'],
    ['BEP20', 'bg-[#eebe21]'],
    ['AVAX', 'bg-red-500'],
    ['DOT', 'bg-pink-500'],
    ['SOL', 'bg-teal-500'],
    ['ADA', 'bg-[#324cc2]'],
    ['TRC20', 'bg-red-400'],
    ['LTC', 'bg-gray-400'],
  ]);

  const getBgColor = (): string => {
    if (popularChains.has(chain)) {
      return popularChains.get(chain) as string;
    }
    return 'bg-[#de7b56]';
  };

  return (
    <div className={`rounded-md ${getBgColor()} text-white-900 text-xs px-1.5 py-0.5 flex items-center justify-center ${!isChainLong ? 'ml-1.5' : ''}`}>
      {chain}
    </div>
  );
};

export default ChainBadge;
