import React from 'react';

type TInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
interface InputProps extends TInputProps {}

const InputAmount = ({ value, onChange }: InputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 
      'Control', 'Meta', 'a', 'c', 'v', 'x', 'z', 'A', 'C', 'V', 'X', 'Z'
    ];
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;

    const isNumberKey = (key: string) => /\d/.test(key);

    if (allowedKeys.includes(event.key) || (event.ctrlKey || event.metaKey)) {
      return;
    }

    if (!isNumberKey(event.key) && (event.key !== '.' && event.key !== ',')) {
      event.preventDefault();
    }

    if ((event.key === '.' || event.key === ',') && (currentValue.includes('.') || currentValue.includes(','))) {
      event.preventDefault();
    }

    if (currentValue === '0' && isNumberKey(event.key)) {
      event.preventDefault();
      onChange && onChange({ 
        ...event, 
        target: { 
          ...event.target, 
          value: '0.' + event.key 
        } as EventTarget & HTMLInputElement 
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value.replace(',', '.');

    if (inputValue.startsWith('.')) {
      inputValue = '0' + inputValue;
    }

    if (/^\d*\.?\d*$/.test(inputValue)) {
      onChange && onChange({ ...event, target: { ...event.target, value: inputValue } });
    }
  };

  return (
    <div className='flex flex-col gap-1'>
      <input
        onKeyDown={handleKeyDown}
        type='text'
        inputMode='decimal'
        value={value}
        onChange={handleChange}
        aria-label="Input amount"
        className='w-full h-[30px] md:text-3xl text-xl border-none outline-none bg-transparent font-bold text-[#d4d4d4] text-right'
      />
    </div>
  );
};

export default InputAmount;
