export const routerLink = {
	home: '/',
	docs: {
		termOfUse: '/docs/term-of-use',
		amlKyc: '/docs/aml-kyc',
		privacyPolicy: '/docs/privacy-policy',
		affiliateProgram: '/docs/affiliate-program',
		forCompetent: '/docs/for-competent',
	},
	exchange: '/exchange',
	auth: {
		signIn: '/auth/sign-in',
		signUp: '/auth/sign-up',
	},
	partner: '/partner'
}